import avax from "assets/img/avax.png";
import bsc from "assets/img/bsc.png";
import eth from "assets/img/eth.png";
import polygon from "assets/img/polygon.png";
import ftm from "assets/img/ftm.png";
export const currentProjects = [
  {
    name: "Duet Protocol (BSC)",
    token: "DUET",
    tokenURI: require("assets/img/duet_logo.png").default,
    backgroundURI: require("assets/img/duet_banner.jpeg").default,
    link: "https://bsc.duet.vault.inc",
    chain: bsc,
    chainId: 56,
    analytics_id:"duet_bsc"
  },
  {
    name: "Duet Protocol",
    token: "DUET",
    tokenURI: require("assets/img/duet_logo.png").default,
    backgroundURI: require("assets/img/duet_banner.jpeg").default,
    link: "https://duet.vault.inc",
    chain: eth,
    chainId: 1,
    analytics_id:"duet",
  },
  {
    name: "Entropyfi",
    token: "ERP",
    tokenURI: require("assets/img/entropyfi_logo.png").default,
    backgroundURI: require("assets/img/entropyfi_banner.png").default,
    link: "https://entropyfi.vault.inc",
    chain: eth,
    chainId: 1,
    analytics_id:"entropyfi",
  },
  {
    name: "Entropyfi (Polygon)",
    token: "ERP",
    tokenURI: require("assets/img/entropyfi_logo.png").default,
    backgroundURI: require("assets/img/entropyfi_banner.png").default,
    link: "http://polygon.entropyfi.vault.inc",
    chain: polygon,
    chainId: 137,
    analytics_id:"entropyfi_polygon"
  },
  {
    name: "Crypto Unicorns",
    token: "RBW",
    tokenURI: require("assets/img/unicorn_logo.png").default,
    backgroundURI: require("assets/img/unicorn_banner.png").default,
    link: "https://cryptounicorns.vault.inc",
    chain: polygon,
    chainId: 137,
    analytics_id:"cryptounicorn"
  },
  {
    name: "BlockchainSpace",
    token: "GUILD",
    tokenURI: require("assets/img/GUILD.png").default,
    backgroundURI: require("assets/img/Guild_banner.png").default,
    link: "https://blockchainspace.vault.inc",
    chain: eth,
    chainId: 1,
    analytics_id:"blockchainspace",
  },
  {
    name: "BlockchainSpace (BSC)",
    token: "GUILD",
    tokenURI: require("assets/img/GUILD.png").default,
    backgroundURI: require("assets/img/Guild_banner.png").default,
    link: "https://bsc.blockchainspace.vault.inc",
    chain: bsc,
    chainId: 56,
    analytics_id:"blockchainspace_bsc"
  },
  {
    name: "X.XYZ",
    token: "XTOKEN",
    tokenURI: require("assets/img/XTOKEN.png").default,
    backgroundURI: require("assets/img/x-banner.jpeg").default,
    link: "https://x.vault.inc",
    chain: eth,
    chainId: 1,
    analytics_id:"x"
  },
  {
    name: "Galaxy Fight Club",
    token: "GFC",
    tokenURI: require("assets/img/galaxyfightclub_logo.png").default,
    backgroundURI: require("assets/img/galaxynft_banner.jpeg").default,
    link: "https://gfc.vault.inc",
    chain: polygon,
    chainId: 137,
    analytics_id:"galaxyfightclub"
  },
  {
    name: "Lootex",
    token: "LOOT",
    tokenURI: require("assets/img/lootex_logo.png").default,
    backgroundURI: require("assets/img/lootex_banner.jpeg").default,
    link: "https://lootex.vault.inc",
    chain: eth,
    chainId: 1,
    analytics_id:"lootex",
    
  },
  {
    name: "Lootex (BSC)",
    token: "LOOT",
    tokenURI: require("assets/img/lootex_logo.png").default,
    backgroundURI: require("assets/img/lootex_banner.jpeg").default,
    link: "https://bsc.lootex.vault.inc",
    chain: bsc,
    chainId: 56,
    analytics_id:"lootex_bsc"
  },
  // {
  //   name: "NITRO",
  //   token: "NITRO",
  //   tokenURI: require("assets/img/NITRO.png").default,
  //   backgroundURI: require("assets/img/nitro-league.jpeg").default,
  //   link: "https://nitroleague.vault.inc",
  //   chainId: 10000,
  // }
];

export const upcomingProjects = [

  {
    name: "YGGSEA",
    token: "SEA",
    tokenURI: require("assets/img/sea.png").default,
    backgroundURI: require("assets/img/ygg-sea.jpeg").default,
    link: "https://twitter.com/yggsea",
    
  },
  {
    name: "YIELD GUILD GAMES",
    token: "YGG",
    tokenURI: require("assets/img/ygg_logo.png").default,
    backgroundURI: require("assets/img/ygg_banner.png").default,
    link: "https://twitter.com/YieldGuild",
  
  },
  {
    name: "KYOKO",
    token: "KYOKO",
    tokenURI: require("assets/img/kyoko.png").default,
    backgroundURI: require("assets/img/kyoko_background.jpeg").default,
    link: "https://twitter.com/kyokoFinance",
  },{
    name: "Gametaverse",
    token: "Gametaverse",
    tokenURI: require("assets/img/gametaverse.png").default,
    backgroundURI: require("assets/img/gametaverse_background.jpeg").default,
    link: "https://twitter.com/GametaverseDAO",
  },{
    name: "Highstreet",
    token: "HIGH",
    tokenURI: require("assets/img/highstreet.png").default,
    backgroundURI: require("assets/img/highstreet_banner.png").default,
    link: "https://twitter.com/highstreetworld",
  },{
    name: "Illiquid DAO",
    token: "JPEGS",
    tokenURI: require("assets/img/IlliquidDAO.png").default,
    backgroundURI: require("assets/img/Illiquiq_banner.png").default,
    link: "https://twitter.com/illiquidDao",
  },{
    name: "Neftify",
    token: "Neftify",
    tokenURI: require("assets/img/neftify_logo.png").default,
    backgroundURI: require("assets/img/neftify_banner.jpeg").default,
    link: "https://twitter.com/neftify",
  },{
    name: "Dracoo Master",
    token: "Dracoo Master",
    tokenURI: require("assets/img/dracoo_logo.png").default,
    backgroundURI: require("assets/img/dracoo_banner.jpeg").default,
    link: "https://twitter.com/Dracoo_Master",
  },{
    name: "Avarik Saga Universe",
    token: "AVRK",
    tokenURI: require("assets/img/avrk_logo.png").default,
    backgroundURI: require("assets/img/avrk_banner.jpeg").default,
    link: "https://twitter.com/avariksaga",
  },{
    name: "ARCADE Inc",
    token: "ARCADE",
    tokenURI: require("assets/img/arcade_logo.jpeg").default,
    backgroundURI: require("assets/img/arcade_banner.jpeg").default,
    link: "https://arcade.inc/",
  },{
    name: "XY Finance",
    token: "XY",
    tokenURI: require("assets/img/xy_logo.png").default,
    backgroundURI: require("assets/img/xy_banner.jpeg").default,
    link: "https://twitter.com/xyfinance",
  },{
    name: "QUOTH",
    token: "QUOTH",
    tokenURI: require("assets/img/quoth_logo.png").default,
    backgroundURI: require("assets/img/quoth_banner.jpeg").default,
    link: "https://twitter.com/Quoth_ai",
  },{
    name: "ARCHE",
    token: "ARCHE",
    tokenURI: require("assets/img/arche_logo.png").default,
    backgroundURI: require("assets/img/arche_banner.jpeg").default,
    link: "https://twitter.com/Arche_Network",
  },{
    name: "Colizeum",
    token: "Colizeum",
    tokenURI: require("assets/img/colizeum_logo.png").default,
    backgroundURI: require("assets/img/colizeum_banner.jpeg").default,
    link: "https://twitter.com/Colizeumcom",
  },{
    name: "Metabase",
    token: "Metabase",
    tokenURI: require("assets/img/metabase_logo.png").default,
    backgroundURI: require("assets/img/metabase_banner.jpeg").default,
    link: "https://twitter.com/Metabase_gg",
  },{
    name: "ForthBox",
    token: "FBX",
    tokenURI: require("assets/img/fbx_logo.png").default,
    backgroundURI: require("assets/img/fbx_banner.jpeg").default,
    link: "https://twitter.com/forthbox",
  },{
    name: "DeNet",
    token: "DeNet",
    tokenURI: require("assets/img/denet_logo.png").default,
    backgroundURI: require("assets/img/denet_banner.jpeg").default,
    link: "https://twitter.com/denetpro",
  },{
    name: "League Of Ancients",
    token: "LOA",
    tokenURI: require("assets/img/loa_logo.png").default,
    backgroundURI: require("assets/img/loa_banner.jpeg").default,
    link: "https://twitter.com/LOAOfficialMOBA",
  },
  
];
