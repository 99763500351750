import React, { useEffect,useState }  from "react";
import {Grid, useMediaQuery} from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import createTheme from "../jss/appTheme";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import avax from "assets/img/avax.png";
import bsc from "assets/img/bsc.png";
import eth from "assets/img/eth.png";
import polygon from "assets/img/polygon.png";
import ftm from "assets/img/ftm.png";
import { currentProjects,upcomingProjects } from "features/configure";
import quantstampIcon from "assets/img/quantstamp.svg";
import certikIcon from "assets/img/certik.png";
import axios from "axios"
import BigNumber from "bignumber.js";
import CustomTable from "components/CustomTable/CustomTable.js";
import _, { isNumber } from "lodash";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';


let contributed = 0;

const renderChain = (row) => {
  return <img src={row.chain} className="icon"/>
}

const detailModifiy = (originalDetail) => {

  return currentProjects.map((row, index) => {
    let minArray = [];
    let maxArray = [];
    let totalTVL = 0;
    

    if(originalDetail[row.analytics_id]){
      totalTVL = originalDetail[row.analytics_id].totalTvl;
      
      if(originalDetail[row.analytics_id].singlePoolAPRMin && !isNaN(parseFloat(originalDetail[row.analytics_id].singlePoolAPRMin.replace(/,/g, ''))) && parseFloat(originalDetail[row.analytics_id].singlePoolAPRMin.replace(/,/g, ''))!=0){
        minArray.push(parseFloat(originalDetail[row.analytics_id].singlePoolAPRMin.replace(/,/g, '')));
      }
      if(originalDetail[row.analytics_id].lpPoolAPRMin && !isNaN(parseFloat(originalDetail[row.analytics_id].lpPoolAPRMin.replace(/,/g, ''))) && parseFloat(originalDetail[row.analytics_id].lpPoolAPRMin.replace(/,/g, ''))!=0){
        minArray.push(parseFloat(originalDetail[row.analytics_id].lpPoolAPRMin.replace(/,/g, '')));
      }
      if(originalDetail[row.analytics_id].singlePoolAPRMax && !isNaN(parseFloat(originalDetail[row.analytics_id].singlePoolAPRMax.replace(/,/g, '')))){
        maxArray.push(parseFloat(originalDetail[row.analytics_id].singlePoolAPRMax.replace(/,/g, '')));
      }
      if(originalDetail[row.analytics_id].lpPoolAPRMax && !isNaN(parseFloat(originalDetail[row.analytics_id].lpPoolAPRMax.replace(/,/g, ''))) && isFinite(parseFloat(originalDetail[row.analytics_id].lpPoolAPRMax.replace(/,/g, '')))){
        maxArray.push(parseFloat(originalDetail[row.analytics_id].lpPoolAPRMax.replace(/,/g, '')));
      }
    } 

    let aprMin = _.min(minArray);
    let aprMax = _.max(maxArray);

    if(isNaN(aprMin) || !isFinite(aprMin)){
      aprMin = 0;
    }
    
    if(isNaN(aprMax) || !isFinite(aprMax)){
      aprMax = 0;
    }

    
    return {
      icon:<img src={row.tokenURI} className="icon" />,
      project:row.name,
      id:row.analytics_id,
      totalTVL:totalTVL,
      minAPR:aprMin,
      maxAPR:aprMax,
      chain:originalDetail[row.analytics_id]?renderChain(row):"-",
      linkurl:row.link,
      chainId:row.chainId,
      tokenPrice:originalDetail[row.analytics_id]?parseFloat(originalDetail[row.analytics_id].tokenPrice).toFixed(4):"-"
    }
    })
}



const LandingContent = () => {
  const [tvl, setTvl] = useState(0);
  const [reward, setReward] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [detail, setDetail] = useState([]);
  const [sortBy, setSortBy] = useState("Total Value Locked");
  const [sortedDetail, setSortedDetail] = useState({});
  const theme = createTheme(true);
  const computer = useMediaQuery(theme.breakpoints.up('sm'));
  const [search, setSearch] = useState("");
  const changeInputValue = (event) => {
    let value = event.target.value;
    setSearch(value);
  };
  const sortDetail = (d,s="Total Value Locked") => {
    
    const kMapping = {
      "Total Value Locked":"totalTVL",
      "Projects":"project",
      "Base Reward APY":"maxAPR",
      "Chain":"chainId",
      "$":"tokenPrice"
    }
    if (s=="Projects") {
      setSortedDetail(_.orderBy(d,[kMapping[s]],["aesc"]));
    } else if(s=="Chain"){
      setSortedDetail(_.orderBy(d,[kMapping[s],"totalTVL"],["aesc","desc"]));
    }
    else {
      setSortedDetail(_.orderBy(d,[kMapping[s],"totalTVL"],"desc"));
    }
    
  }

  useEffect(async() => {
    let res = await axios.get("https://analytics.vault.inc/api/stats")
    if(res.data){
      setTvl(res.data.totalTvl);
      setReward(res.data.totalClaimed);
      setDetail(detailModifiy(res.data.stats));
    }
    
    const id = setInterval(async() => {
      let res = await axios.get("https://analytics.vault.inc/api/stats")
      if(res.data){
        setTvl(res.data.totalTvl)
        setReward(res.data.totalClaimed)
        setDetail(detailModifiy(res.data.stats));
      }
    }, 10000);
    return () => clearInterval(id);
}, []);

  useEffect(() => {
    sortDetail(detail,sortBy);
  }, [detail,sortBy]);
  return (
    <>
      <Container style={{ paddingTop: "10vh", lineHeight: 0.8 }}>
              
     
         
        
        <Grid container justifyContent="center" alignItems="center" spacing={3} style={{marginTop:"150px"}}>
            
          <Grid item xs={12} sm={4}>
            <div  style={{}}>
              <div style={{fontSize:20, fontWeight:600, textAlign:"center", marginTop:20}}>Supported Chains</div>
              <div style={{marginTop:20,textAlign:"center"}}>
                  <img
                    src={eth}
                    className="lgIcon"
                  />
                  <img
                    src={bsc}
                    className="lgIcon"
                  />
                  <img
                    src={avax}
                    className="lgIcon"
                  />
                  <img
                    src={polygon}
                    className="lgIcon"
                  />
                  <img
                    src={ftm}
                    className="lgIcon"
                  />
     </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="valueCard">Total Value Locked
            <div className="header">${new BigNumber(parseFloat(tvl+contributed)).toFormat(0)}</div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="valueCard">Total Rewards Claimed
            <div className="header">${new BigNumber(parseFloat(reward).toFixed(0)).toFormat(0)}</div>
            </div>
          </Grid>
          <Grid item xs={12}>
          <div style={{ textAlign:computer?"right":"center"}}>
                  <Button size="large" color="primary"
                onClick={()=>{
                  window.scrollTo(0,950)
                  }}>Explore</Button>
                  <Button size="large" color="primary"
                onClick={()=>{
                  }}>Listing Inquirt</Button>
            </div>
          </Grid>
         
        </Grid>
      
      </Container>
  
      <div
        style={{
          marginTop: 50,
          maxWidth: 1200,
          margin: "80px auto",
          textAlign: "center",
          position: "relative",
        }}
      >
        {/* <div style={{ marginBottom: 150 }}>
          <h1 className="header">Total Locked Value</h1>
          <h1 className="header">${1000000000}</h1>
        </div> */}
         <div style={{float:"right"}}> 
          <CustomOutlinedInput
                value={search}
                placeholder="search"
                onChange={changeInputValue}
              />
        </div>
        <h1 className="header" style={{fontSize:30, textAlign:'left'}}>Current Projects</h1>
       
        <Grid container >
        <Grid item xs={12} md={4} className="cardSubHeader" style={{cursor:"pointer"}} onClick={()=>{
          setSortBy("Projects");
        }}>
         {sortBy == "Projects"? "Project ↓":"Project"}
        </Grid>
        <Grid item xs={12} md={2} className="cardSubHeader" style={{cursor:"pointer"}} onClick={()=>{
          setSortBy("Total Value Locked");
        }}>
        {sortBy == "Total Value Locked"? "Total Value Locked ↓":"Total Value Locked"}
        </Grid>
        <Grid item xs={12} md={2} className="cardSubHeader" style={{cursor:"pointer"}} onClick={()=>{
          setSortBy("Base Reward APY");
        }}>
        {sortBy == "Base Reward APY"? "Base Reward APY ↓":"Base Reward APY"}
        </Grid>
        <Grid item xs={12} md={2} className="cardSubHeader">
        Extra Rewards
        </Grid>
        <Grid item xs={12} md={1} className="cardSubHeader" style={{cursor:"pointer"}} onClick={()=>{
          setSortBy("Chain");
        }}>
        {sortBy == "Chain"? "Chain ↓":"Chain"}
        </Grid>
          {_.map(sortedDetail,(data) => {
            const project = _.find(currentProjects,{"analytics_id":data.id})
            if( search && !data.project.toLowerCase().includes(search.toLowerCase())
            && !project.token.toLowerCase().includes(search.toLowerCase())){
              return
            }
            return (
              <Grid item xs={12} md={12}>
                <Link to="">
                  {" "}
                  <div
                    style={{
                      margin:10,
                      backgroundColor: `#363638`,
                      borderRadius:20,                      
                      backgroundSize: "contain",
                      position: "relative",
                      cursor: "pointer",
                      fontSize:18,
                      color:"white"
                    }}
                    onClick={() => window.open(data.linkurl)}
                  >
                    
                    <img src={project.backgroundURI} style={{ position: "absolute", left: 0, top:0, width:computer?"30%":"100%",height:"100%", object:"cover"
                    ,borderRadius:computer?"20px 0px 0px 20px":20,zIndex:5, opacity:0.5}}/>
                    <Grid container style={{zIndex:10, position: "relative", padding:15,}} alignItems="center">
                      <Grid item xs={12} sm={4} style={{textAlign:"left"}}>
                      <Grid container alignItems="center">
                      <Grid item xs={3} style={{textAlign:"center"}} >
                      <img src={project.tokenURI} className="icon" />
                      </Grid>
                      <Grid item xs={9  }>
                      <div style={{fontWeight:900, fontSize:20}}>
                        {project.token}
                      </div>
                      <div style={{fontSize:14}}>{data.project}</div>
                      </Grid>
                      </Grid>
                      </Grid>
                      <Grid item xs={12} sm={2} >     
                      ${new BigNumber(data.totalTVL).toFormat(0)} 
                      </Grid>
                      <Grid item xs={12} sm={2} >     
                      {data.minAPR} - {data.maxAPR} %
                      </Grid>
                      <Grid item xs={12} sm={2} >     
                      Soon
                      </Grid>
                      <Grid item xs={12} sm={1} >     
                      {data.chain}
                      </Grid>
                      <Grid item xs={12} sm={1} >     
                        <DoubleArrowIcon/>
                      </Grid>
                    </Grid>
              
                    
                  </div>
                </Link>
              </Grid>
            );
          })}
        </Grid>
        <h1 className="header" style={{fontSize:30, textAlign:'left', marginTop:100}}>Upcoming Projects</h1>
        <Grid container spacing={1}>
          {upcomingProjects.map((data) => {
            return (
              <Grid item xs={12} md={3}>
                <Link to="">
                  {" "}
                  <div
                    className="projectCard"
                    style={{
                      background: `url(${data.backgroundURI})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      backgroundBlendMode: "multiply",
                      backgroundSize: "cover",
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onClick={() => window.open(data.link)}
                  >
                    <img src={data.tokenURI} className="icon" />
                    <span style={{fontSize:18}}>{data.token}</span>
                    <div style={{ position: "absolute", bottom: 15,right:15, width:200, textAlign:"right"}}>
                      <span className="cardHeader" style={{}}>{data.name}</span>
                    </div>
                  </div>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default LandingContent;
