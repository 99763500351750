import React, { useEffect,useState }  from "react";
import styled from "styled-components";
import { Grid, Typography, Accordion, AccordionDetails, AccordionSummary, useMediaQuery,Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import createTheme from "./jss/appTheme";
import axios from "axios"
import BigNumber from "bignumber.js";
import { currentProjects } from "features/configure";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import Button from "components/CustomButtons/Button";
// import { Slide } from 'react-slideshow-image';
import _, { isNumber } from "lodash";
import { Slide, Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    color:"#FFFFFF",
    fontSize:21,
    textAlign:"left",
    padding:12
  },
  accordion: {
    backgroundColor: "transparent",
    border: "1px solid white",

    boxShadow: "none",
    "&.Mui-expanded": {
      marginTop: 0,
    },
    "&.MuiAccordion-root:before": {
      height: 0
    },
    "&.Mui-disabled": {
      backgroundColor: "transparent",
    }
  },
  switchableTitle: {
    borderRadius: 10,
    "&.Mui-expanded": {
      borderRadius: "10px 10px 0 0",
    },

  },
}));


const LandingPage = () => {
  const theme = createTheme(true);
  const classes = useStyles();
  const [reward, setReward] = useState(0);
  const { t } = useTranslation();
  const [tvl, setTvl] = useState(0);
  let contributed = 0;
  const computer = useMediaQuery(theme.breakpoints.up('sm'));
  useEffect(async() => {
    let res = await axios.get("https://analytics.vault.inc/api/stats")
    if(res.data){
      setTvl(res.data.totalTvl);
      setReward(res.data.totalClaimed);
    }
    
    const id = setInterval(async() => {
      let res = await axios.get("https://analytics.vault.inc/api/stats")
      if(res.data){
        setTvl(res.data.totalTvl)
        setReward(res.data.totalClaimed)

      }
    }, 10000);
    return () => clearInterval(id);
}, []);
const strategies = [
  {
    title:"Stake & Lock",
    description:"",
    url: require('assets/img/strategy1.png').default
  },
  {
    title:"NFT",
    description:"NFT staking enables projects to incentive NFT holders to stake and farm their protocol tokens, additionally vault can also support multi-rewards.",
    url: require('assets/img/strategy2.png').default
  },
  {
    title:"Bonding",
    description:"Protocol owned liquidity is a liquidity provision strategy pioneered by Olympus DAO to provide liquidity to tokens on DEX. Instead of relying on providing incentive to the market to provide liquidity to liquidity pools, the protocol owned liquidity model instead utilizes a “bonding” mechanism that doesn't create sell pressure.",
    url: require('assets/img/strategy3.png').default
  },
];
const tools = [
  {
    title:"Leaderboard",
    description:"Customizable campaigns to incentives users with multi-rewards and NFTs",
    url: require('assets/img/tool1.png').default
  },
  {
    title:"On-chain Analysis",
    description:"For each Project",
    url: require('assets/img/tool2.png').default
  },
  {
    title:"Marketing Exposures",
    description:"for New/Old Projectsv",
    url: require('assets/img/tool3.png').default
  },
];
const coppers = [
  {
    title:"Spot price without any buy/sell orders",
    description:"Due to the design of Balancer Liquidity Bootstrapping Pool (BLBP) on Copperlaunch, the token price would slowly decreases to the final end price if no one purchases the token.",
    url: require('assets/img/copper1.png').default
  },
  {
    title:"",
    description:"Protocol owned liquidity is new approach pioneered by Olympus DAO to provide liquidity to tokens on decentralised exchanges. Instead of relying on providing incentives to the market to provide liquidity to liquidity pools, the protocol owned liquidity model instead utilises a “bonding” mechanism.",
    url: require('assets/img/copper2.png').default
  },

];

const properties = {
  duration: 10000,
  slidesToShow:computer?2:1,
  slidesToScroll:computer?2:1,
  autoplay:true,
  infinite: false,
  indicators: true,
};
const properties2 = {
  duration: 5000,
  slidesToShow:1,
  autoplay:true,
  infinite: true,
  arrows:false,
  scale: 1.4,
  indicators: true,
};
const properties3 = {
  duration: 5000,
  slidesToShow:1,
  autoplay:true,
  infinite: true,
  arrows:false,
  scale: 1.4,
};
let projectProperties = {
  duration: 5000,
  slidesToShow:computer?6:2,
  slidesToScroll:computer?4:2,
  autoplay:true,
  infinite: true,
  arrows:false,
  indicators: true,
};

const renderCards = (data)=>{
  return(
    data.map((slideImage, index)=> (
      <div className="card" key={index}>
        <Grid container>
          <Grid item xs={12} sm={6} style={{padding:30}}>
            <div style={{fontSize:40, fontWeight:700, marginBottom:25 }}>{slideImage.title}</div>
            <div style={{fontSize:20, fontWeight:500}}>{slideImage.description}</div>
          </Grid>
          <Grid item xs={12} sm={6}>
          <img src={slideImage.url} style={{width:"100%",objectFit:"contain"}}/>
          </Grid>
        </Grid>
        
      </div>
    ))
  )
}

const renderCommunity =(title,icon, link)=>{
  return(
    <div style={{border:"1px solid white", borderRadius:15, padding:"50px 25px",margin:10}}>
      <img className="lgIcon" src={icon}/>
      <div style={{fontSize:24, fontWeight:600, margin:10}}>{title}</div>
      <a href={link} target="_blank"><Button color="follow">Follow</Button></a>
    </div>
  )

}
  return (
    <>
        <div style={{padding:"40px 0", textAlign:"center", margin:"200px auto"}}>
            <div className="each-slide" style={{margin:"10px auto"}}>
              <div style={{textAlign:"center" }}>
                  <div style={{fontSize:38, fontWeight:600}}> Total Value Locked</div>
                  <div style={{fontSize:computer?101:40,background: "linear-gradient(0deg, #FFFFFF 30.83%, rgba(255, 255, 255, 0) 143.44%)",
                    "-webkit-background-clip": "text",
                    "-webkit-text-fill-color": "transparent",
                    "background-clip": "text",
                    "text-fill-color": "transparent"}}>
                  ${new BigNumber(parseFloat(tvl+contributed)).toFormat(0)}
                  </div>
                </div>
                </div>
            
                <Link to={"/home"}><Button color="secondary" style={{marginTop:25}}>Explore</Button></Link>
         </div>
         <div style={{backgroundColor:"black", padding:5}}>
           <div style={{maxWidth:1400, margin:"0 auto"}}>
                <Slide {...projectProperties}>
                {currentProjects.map((data) => {
              return (
                <div className="each-slide" style={{margin:"10px auto"}}>
                    <div
                      className="projectCard"
                      style={{
                        background: `url(${data.backgroundURI})`,
                        backgroundPosition: "center",
                        height:90,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        backgroundBlendMode: "multiply",
                        backgroundSize: "cover",
                        position: "relative",
                        cursor: "pointer",
                      }}
                      onClick={() => window.open(data.link)}
                    >
                      <Grid container alignItems="center">
                        <Grid item xs={5}><img src={data.tokenURI} className="icon" /></Grid>
                        <Grid item xs={7}><span style={{fontSize:22, fontWeight:600}}>{data.name}</span></Grid>
                      </Grid>
                      
                     
                    </div>
                  </div>
              );
            })}
                </Slide>
                </div>
            </div>
          <Container style={{margin:"140px auto", textAlign:"center"}}>
 
          

          
            <h1 className="subheader" style={{marginTop:160,marginBottom:80}}>How we do it?</h1>
            <Zoom  {...properties2}>
            <div className="each-slide" style={{margin:"10px auto"}}>
                <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={2}></Grid>
                  <Grid item xs={12} sm={6} style={{textAlign:"left", margin:"0 auto"}}>
                    <h1 className="largeHeader" style={{textAlign:"left"}}>Safty First</h1>
                    <div style={{fontSize:38}}>
                      <div >Lorem ipsum dolor sit amet, consectetur adipiscing elit. </div>
                      <div>Sit Amet,Consectetur</div>
                      <div> Adipiscing Elit. </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <img src={require("assets/img/method1.png").default} style={{height:370,objectFit:"contain"}}/>
                    </Grid>
                </Grid>
              </div>
              <div className="each-slide" style={{margin:"10px auto"}}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <img src={require("assets/img/method2.png").default} style={{height:computer?370:250,objectFit:"contain"}}/>
                    </Grid>
                  <Grid item xs={12} sm={6} style={{textAlign:computer?"right":"left", margin:"0 auto"}}>
                    <h1 className="largeHeader" style={{textAlign:computer?"right":"left"}}>Multi Chain</h1>
                    <div style={{fontSize:38}}>
                      <div >Lorem Ipsum Dolor </div>
                      <div>Sit Amet,Consectetur</div>
                      <div> Adipiscing Elit. </div>
                    </div>
                  </Grid>
                  
                </Grid>
              </div>
              <div className="each-slide" style={{margin:"10px auto"}}>
                <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={2}></Grid>
                  <Grid item xs={12} sm={6} style={{textAlign:"left", margin:"0 auto"}}>
                    <h1 className="largeHeader" style={{textAlign:"left"}}>Reward</h1>
                    <div style={{ fontSize:38}}>
                      <div >Lorem ipsum dolor sit amet, consectetur adipiscing elit. </div>
                      <div>Sit Amet,Consectetur</div>
                      <div> Adipiscing Elit. </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <img src={require("assets/img/method3.png").default} style={{height:370,objectFit:"contain"}}/>
                    </Grid>
                </Grid>
              </div>
            </Zoom>
           
            <div >
              <h1 className="subheader" style={{marginTop:160,marginBottom:80}}>Vault Staking Strategies</h1>
              {renderCards(strategies)}
              {/* <Slide  {...properties} className={computer?"custom-slideshow":""}>
                {strategies.map((slideImage, index)=> (
                    <div className="each-slide" key={index} style={{margin:"10px"}}>
                      <img src={slideImage.url} style={{width:"100%",objectFit:"contain"}}/>
                    </div>
                  ))} 
                </Slide> */}
             </div>
             <div>
              <h1 className="subheader"  style={{marginTop:160,marginBottom:80}}>Vault Tools</h1>
              {renderCards(tools)}
              {/* <Slide  {...properties} className={computer?"custom-slideshow":""}>
                {tools.map((slideImage, index)=> (
                    <div className="each-slide" key={index} style={{margin:"10px"}}>
                      <img src={slideImage.url} style={{width:"100%",objectFit:"contain"}}/>
                    </div>
                  ))} 
                </Slide> */}
              </div>

            <div>
              <h1 className="subheader"  style={{marginTop:160,marginBottom:80}}>How does Vault help with<br/> project’s IDO on Copperlaunch?</h1>
              {renderCards(coppers)}
              {/* <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} sm={6}>
                <img src={require("assets/img/copper1.png").default} style={{width:"100%",objectFit:"contain"}}/>
                </Grid>
                <Grid item xs={12} sm={6} >
                 <img src={require("assets/img/copper2.png").default} style={{width:"100%",objectFit:"contain"}}/>
              </Grid>
              </Grid> */}
             
            </div>
          <div >
            <h1 className="subheader" style={{marginTop:150,marginBottom:60}}>Audits</h1>
            <Grid container spacing={3} style={{ margin:"0 auto", textAlign:"center", padding:10}}>

              <Grid item xs={12} sm={3}>
                <img src={require("assets/img/audit1.png").default} style={{height:40,objectFit:"contain"}}/>
              </Grid>
        
              <Grid item xs={12} sm={3}>
                <img src={require("assets/img/audit2.png").default} style={{height:40,objectFit:"contain"}}/>
              </Grid>
              <Grid item xs={12} sm={3}>
                <img src={require("assets/img/audit3.png").default} style={{height:40,objectFit:"contain"}}/>
              </Grid>
              <Grid item xs={12} sm={3}>
                <img src={require("assets/img/audit4.png").default} style={{height:40,objectFit:"contain"}}/>
              </Grid>
      
            </Grid>
          </div>
       
            <div >
              <h1 className="subheader" style={{marginTop:160,marginBottom:80}}>Join our community</h1>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                 {renderCommunity("Twitter",require("assets/img/twitter.png").default,"https://twitter.com/vaultinc_")}
                </Grid>
                <Grid item xs={12} sm={4}>
                {renderCommunity("Discord",require("assets/img/discord.png").default,"https://discord.com/invite/5eucBfYJrD")}
                </Grid>
                <Grid item xs={12} sm={4}>
                {renderCommunity("Mediem",require("assets/img/mediem.png").default,"")}
                </Grid>
              </Grid>
             </div>

            <div >
              <h1 className="subheader" style={{marginTop:160,marginBottom:80}}>Frequently Asked questions</h1>
              <Accordion className={classes.accordion} style={{borderRadius: "20px 20px 0 0px"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>How does soft-staking work?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>When will I get my reward?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography className={classes.heading}>What if I accidentally move my NFT during the stake?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion} style={{borderRadius: "0 0 20px 20px "}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography className={classes.heading}>Can I stake while storing my NFT in a hardware wallet?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    
                  </Typography>
                </AccordionDetails>
              </Accordion>
              
            </div>
            </Container>
    </>

  );
};


export default LandingPage;
